import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import HomePage from './views/homePage';

import Dashboard from './views/dashboard';
import Request from './views/request';


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>


                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                                                <Route
                            path="/dashboard/:id"
                            exact
                            render={(...renderProps) => (
                                <Dashboard {...renderProps} {...this.props} />
                            )}
                        />
                                                <Route
                            path="/request/:id"
                            exact
                            render={(...renderProps) => (
                                <Request {...renderProps} {...this.props} />
                            )}
                        />

                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;
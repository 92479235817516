import React, { Component } from 'react';
import { Link } from 'react-router-dom';



export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div className="wrapper">
            <Wrapped {...props} />
        </div>
    );
};

export default DefaultLayout;
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/logo.svg';
import fb from '../assets/fb.svg';
import instagram from '../assets/instagram.svg';
import bg from '../assets/bg.png';

import pointer from '../assets/dashboard/pointer.svg';
import rect from '../assets/dashboard/rect.svg';
import circle from '../assets/dashboard/circle.svg';
import triangle from '../assets/dashboard/triangle.svg';
import line from '../assets/dashboard/line.svg';
import pen from '../assets/dashboard/pen.svg';
import text from '../assets/dashboard/text.svg';
import page from '../assets/dashboard/page.png';
import template1 from '../assets/dashboard/template1.jpg';
import template2 from '../assets/dashboard/template2.png';
import template3 from '../assets/dashboard/template3.jpg';
import template4 from '../assets/dashboard/template4.png';

import plus from '../assets/dashboard/plus.svg';
import { SketchField, Tools } from '../react-sketch';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const tools = [
    Tools.Pan,
    Tools.Rectangle,
    Tools.Circle,
    Tools.Line,
    Tools.Pencil,
]

let colors = ['#CCFF90', '#FFF475', '#A7FFEB', '#CCF0F8', '#AECBFA'];

class Dashborad extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            activeIndex: 0, yScroll: 0, selectedTool: 0, selectedColor: 0, selectedPage: 0, pages: []
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (window.innerWidth <= 768) {
            alert("This tool is only available on desktop!");

        }

        fetch('https://devlery-api.novamedia.agency/pages/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                data: result,
                pages: [
                    {
                        name: 'Home page',
                        notes: [],
                        template: result && result.pages && result.pages[0],
                        value: { objects: [] },
                        parent: null
                    }
                ]
            })
        });

    }

    submit() {
        let errors = {};
        let error;
        if (!this.state.name) {
            errors.name = true;
            error = true;
        }
        if (!this.state.email) {
            errors.email = true;
            error = true;
        }
        if (!this.state.phone) {
            errors.phone = true;
            error = true;
        }

        if (error) {
            this.setState({
                errors: errors
            })
            return;
        };


        fetch('https://devlery-api.novamedia.agency/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                timestamp: Math.floor(new Date().getTime() / 1000),
                pages: this.state.pages,
                templateName: this.state.data && this.state.data.name,
                templateId: this.props[0].match.params.id
            })

        }).then((res) => res.json()).then((result) => {
        });

    }

    render() {

        console.log(this.state.pages);
        return (
            <div className="dashboard-wrap">
                {/*
                <header>
                    <Isvg src={logo} />
                    <Link to='/'><button>NEXT STEP</button></Link>
                </header>
*/}
                <header>
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to='/'>HOME</Link></li>
                            <li><Link to='/'>HOW WE WORK</Link></li>
                            <li><Link to='/'>PRICING</Link></li>
                            <li><Link to='/'>CONTACT</Link></li>

                            <li><button className="order-now-btn" onClick={() => {
                                if (!this.templatesSection) {
                                    return;
                                }
                                this.templatesSection.scrollIntoView({ behavior: 'smooth' });

                            }}> ORDER NOW </button></li>

                        </ul>
                    </div>
                    <div className="social">
                        FOLLOW US
                        <a href="#" target="_blank"><Isvg src={fb} /></a>
                        <a href="#" target="_blank"><Isvg src={instagram} /></a>

                    </div>
                </header>

                <div className="toolbox">
                    <ul>
                        <li className={this.state.selectedTool == 0 ? 'active' : null} onClick={() => this.setState({ selectedTool: 0 })}><Isvg src={pointer} /></li>
                        <li className={this.state.selectedTool == 1 ? 'active' : null} onClick={() => this.setState({ selectedTool: 1 })}><Isvg src={rect} /></li>
                        <li className={this.state.selectedTool == 2 ? 'active' : null} onClick={() => this.setState({ selectedTool: 2 })}><Isvg src={circle} /></li>
                        <li className={this.state.selectedTool == 3 ? 'active' : null} onClick={() => this.setState({ selectedTool: 3 })}><Isvg src={line} /></li>
                        <li className={this.state.selectedTool == 4 ? 'active' : null} onClick={() => this.setState({ selectedTool: 4 })}><Isvg src={pen} /></li>
                        {/* <li onClick={() => this.setState({selectedTool: 6})}><Isvg src={text} /></li>*/}

                    </ul>
                </div>

                <div className="sidebar">
                    <ul>
                        {
                            this.state.pages.map((item, idx) => {
                                if (item.parent == null)
                                    return (
                                        <li className={idx == this.state.selectedPage ? 'active' : null} ><input onClick={() => this.setState({ selectedPage: idx })} type="text" value={item.name} onChange={(e) => {
                                            let pages = this.state.pages;
                                            pages[idx].name = e.target.value;
                                            this.setState({ pages });

                                        }}></input>

                                            <button onClick={() => {

                                                let pages = this.state.pages;
                                                pages.push({
                                                    name: 'Subpage',
                                                    template: this.state.data && this.state.data.pages && this.state.data.pages[0],
                                                    parent: idx,
                                                    notes: [],
                                                    borard: null,
                                                    value: { objects: [] }
                                                })

                                                this.setState({
                                                    pages
                                                })

                                            }}>  <Isvg src={plus} /></button>


                                            {
                                                <ul>
                                                    {
                                                        this.state.pages.map((subpage, sidx) => {
                                                            if (subpage.parent == idx)
                                                                return (
                                                                    <li className={sidx == this.state.selectedPage ? 'active' : null} ><input onClick={() => this.setState({ selectedPage: sidx })} type="text" value={subpage.name} onChange={(e) => {
                                                                        let pages = this.state.pages;
                                                                        pages[sidx].name = e.target.value;
                                                                        this.setState({ pages });

                                                                    }}></input>
                                                                    </li>
                                                                )
                                                        })
                                                    }
                                                </ul>
                                            }

                                        </li>
                                    )
                            })
                        }
                        <li> <div> {
                            this.state._newPage ?
                                <input type="text" value={this.state._pageName} onChange={(e) => this.setState({ _pageName: e.target.value })} />
                                : null
                        }
                            <button onClick={() => {
                                if (!this.state._newPage) {
                                    this.setState({
                                        _newPage: true,
                                        _pageName: 'Page #' + this.state.pages.length
                                    })
                                    return;
                                } else {
                                    let pages = this.state.pages;
                                    pages.push({
                                        name: this.state._pageName,
                                        template: this.state.data && this.state.data.pages && this.state.data.pages[0],
                                        notes: [],
                                        borard: null,
                                        value: { objects: [] }
                                    })

                                    this.setState({
                                        pages,
                                        _newPage: null
                                    })
                                }
                            }}>  <Isvg src={plus} /></button> </div></li>

                    </ul>

                    <div className="form">
                        <div className="input-wrap">
                            <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder="Enter your name" />
                        </div>
                        <div className="input-wrap">

                            <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Enter your e-mail address" />
                        </div>
                        <div className="input-wrap">

                            <input type="text" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} placeholder="Enter your phone number" />
                        </div>
                        <button className="button" onClick={this.submit}>SUBMIT</button>

                    </div>

                </div>


                <div className="area">
                    <h3>{this.state.data && this.state.data.name}</h3>
                    <img src={this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].template} />

                    <SketchField width='100%'
                        height='2000px'
                        tool={tools[this.state.selectedTool]}
                        lineColor={colors[this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].notes.length]}
                        lineWidth={3}

                        value={this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].value}
                        onChange={(e, t) => {
                            if (!e) {
                                return;
                            }
                            if (e.type !== "mouseup") {
                                return;
                            }

                            if (this.state.selectedTool == 0) {
                                return;
                            }

                            let pages = this.state.pages;
                            pages[this.state.selectedPage].value = { objects: JSON.parse(JSON.stringify(t)) };

                            pages[this.state.selectedPage].notes.push({
                                color: colors[pages[this.state.selectedPage].notes.length],
                                text: 'Note #' + pages[this.state.selectedPage].notes.length
                            })

                            this.setState({
                                selectedColor: this.state.selectedColor + 1,
                                pages
                            })
                        }}
                    />

                </div>
                <div className="right-area">
                <h3>NOTES</h3>
                    <div className="notes">
                        {
                            this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].notes.map((item, idx) => {
                                return (
                                    <div className="note" style={{ backgroundColor: item.color }}>
                                        <textarea value={item.text} onChange={(e) => {
                                            let pages = this.state.pages;
                                            pages[this.state.selectedPage].notes[idx].text = e.target.value;
                                            this.setState({ pages });
                                        }}></textarea>
                                    </div>

                                )
                            })
                        }
                    </div>

                    <div className="templates">
                        {
                            this.state.data && this.state.data.pages && this.state.data.pages.map((item, idx) => {
                                return (
                                    <div key={idx} className="image" onClick={() => {
                                        let pages = this.state.pages;
                                        pages[this.state.selectedPage].template = item;
                                        this.setState({
                                            pages
                                        })
                                    }}>
                                        <img src={item} />
                                    </div>

                                )
                            })
                        }

                    </div>

                </div>

            </div >
        );
    }
}

export default Page(Dashborad);
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/logo.svg';
import fb from '../assets/fb.svg';
import instagram from '../assets/instagram.svg';
import bg from '../assets/bg.png';

import pointer from '../assets/dashboard/pointer.svg';
import rect from '../assets/dashboard/rect.svg';
import circle from '../assets/dashboard/circle.svg';
import triangle from '../assets/dashboard/triangle.svg';
import line from '../assets/dashboard/line.svg';
import pen from '../assets/dashboard/pen.svg';
import text from '../assets/dashboard/text.svg';
import page from '../assets/dashboard/page.png';
import template1 from '../assets/dashboard/template1.jpg';
import template2 from '../assets/dashboard/template2.png';
import template3 from '../assets/dashboard/template3.jpg';
import template4 from '../assets/dashboard/template4.png';

import plus from '../assets/dashboard/plus.svg';
import { SketchField, Tools } from '../react-sketch';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const tools = [
    Tools.Pan,
    Tools.Rectangle,
    Tools.Circle,
    Tools.Line,
    Tools.Pencil,
]

let colors = ['#CCFF90', '#FFF475', '#A7FFEB', '#CCF0F8', '#AECBFA'];

class Request extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0, yScroll: 0, selectedTool: 0, selectedColor: 0, selectedPage: 0, pages: []
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (window.innerWidth <= 768) {
            alert("This tool is only available on desktop!");

        }

        fetch('https://devlery-api.novamedia.agency/requests/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            this.setState(result)
        });

    }


    render() {

        console.log(this.state.pages);
        return (
            <div className="dashboard-wrap">
                {/*
                <header>
                    <Isvg src={logo} />
                    <Link to='/'><button>NEXT STEP</button></Link>
                </header>
*/}
                <header>
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to='/'>HOME</Link></li>
                            <li><Link to='/'>ABOUT DEVLERY</Link></li>
                            <li><Link to='/'>HOW WE WORK</Link></li>
                            <li><Link to='/'>TEMPLATES</Link></li>
                            <li><Link to='/'>PRICING</Link></li>
                            <li><Link to='/'>CONTACT</Link></li>

                        </ul>
                    </div>
                    <div className="social">
                        FOLLOW US
                        <a href="#" target="_blank"><Isvg src={fb} /></a>
                        <a href="#" target="_blank"><Isvg src={instagram} /></a>

                    </div>
                </header>

                <div className="sidebar">
                    <ul>
                        {
                            this.state.pages.map((item, idx) => {
                                if (item.parent == null)
                                    return (
                                        <li className={idx == this.state.selectedPage ? 'active' : null} ><input onClick={() => this.setState({ selectedPage: idx })} type="text" value={item.name} onChange={(e) => {
                                            let pages = this.state.pages;
                                            pages[idx].name = e.target.value;
                                            this.setState({ pages });

                                        }}></input>



                                            {
                                                <ul>
                                                    {
                                                        this.state.pages.map((subpage, sidx) => {
                                                            if (subpage.parent == idx)
                                                                return (
                                                                    <li className={sidx == this.state.selectedPage ? 'active' : null} ><input onClick={() => this.setState({ selectedPage: sidx })} type="text" value={subpage.name} onChange={(e) => {
                                                                        let pages = this.state.pages;
                                                                        pages[sidx].name = e.target.value;
                                                                        this.setState({ pages });

                                                                    }}></input>
                                                                    </li>
                                                                )
                                                        })
                                                    }
                                                </ul>
                                            }

                                        </li>
                                    )
                            })
                        }

                    </ul>

                    <div className="form">
                        <div className="input-wrap">
                            <input type="text" value={this.state.name}  placeholder="Enter your name" />
                        </div>
                        <div className="input-wrap">

                            <input type="text" value={this.state.email}  placeholder="Enter your e-mail address" />
                        </div>
                        <div className="input-wrap">

                            <input type="text" value={this.state.phone}  placeholder="Enter your phone number" />
                        </div>

                    </div>

                </div>


                <div className="area">
                    <h3>{this.state.templateName}</h3>
                    <img src={this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].template} />

                    <SketchField width='100%'
                        height='2000px'
                        tool={tools[this.state.selectedTool]}
                        lineColor={colors[this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].notes.length]}
                        lineWidth={3}

                        value={this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].value}
                        
                    />

                </div>
                <div className="right-area">
                    <h3>NOTES</h3>
                    <div className="notes">
                        {
                            this.state.pages && this.state.pages[this.state.selectedPage] && this.state.pages[this.state.selectedPage].notes.map((item, idx) => {
                                return (
                                    <div className="note" style={{ backgroundColor: item.color }}>
                                        <textarea value={item.text}></textarea>
                                    </div>

                                )
                            })
                        }
                    </div>

                </div>

            </div >
        );
    }
}

export default Page(Request);
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from "react-slick";

import logo from '../assets/logo.svg';
import fb from '../assets/fb.svg';
import instagram from '../assets/instagram.svg';
import bg from '../assets/bg.png';
import user from '../assets/user.png';
import quote from '../assets/quote.svg';
import anim from '../assets/anim.png';
import facebook from '../assets/facebook.svg';
import footerInstagram from '../assets/footer-instagram.svg';
import mail from '../assets/mail.svg';
import phone from '../assets/phone.svg';
import map from '../assets/map.svg';
import footerLogo from '../assets/footer-logo.svg';
import Button from '../components/button';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = { activeIndex: 0, yScroll: 0, templates: [], categories: [], reviews: [] };

    }


    componentDidMount(){
        fetch('https://devlery-api.novamedia.agency/pages/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                templates: result
            })
        });
        fetch('https://devlery-api.novamedia.agency/categories/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                categories: result
            })
        });
        fetch('https://devlery-api.novamedia.agency/reviews/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                reviews: result
            })
        });

    }


    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 1 : 2,
            slidesToScroll: 1
        };


        return (
            <div className="home-wrap">

                <div className="overlay">
                    <img src={bg} />
                    <div className="content">
                        <h1>You choose we deliver</h1>
                        <h6>Choose your favorite style, make comments with graphic instructions and we will make you website very fast.</h6>
                        <Button onClick={() => {
                            if (!this.templatesSection) {
                                return;
                            }
                            this.templatesSection.scrollIntoView({ behavior: 'smooth' });

                        }}>ORDER YOUR WEBSITE NOW</Button>
                    </div>
                </div>
                <header>
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to='/'>HOME</Link></li>
                            <li><Link to='/'>HOW WE WORK</Link></li>
                            <li><Link to='/'>PRICING</Link></li>
                            <li><Link to='/'>CONTACT</Link></li>

                            <li><button className="order-now-btn" onClick={() => {
                            if (!this.templatesSection) {
                                return;
                            }
                            this.templatesSection.scrollIntoView({ behavior: 'smooth' });

                        }}> ORDER NOW </button></li>

                        </ul>
                    </div>
                    <div className="social">
                        FOLLOW US
                        <a href="#" target="_blank"><Isvg src={fb} /></a>
                        <a href="#" target="_blank"><Isvg src={instagram} /></a>

                    </div>
                </header>

                <section className="templates" ref={(node) => this.templatesSection = node}>
                    <Container>
                        <Row>
                            <Col lg="12" className="head">
                                <h3>Choose <span>Your Template</span></h3>
                                <div className="sort">
                                    <span>Select Category</span>
                                    <div className="select">
                                        <button onClick={() => this.setState({ _showMenu: true })}>
                                            {!this.state.selectedCategory ? 'All' : this.state.selectedCategory}
                                        </button>
                                        {this.state._showMenu ? <ul className="menu" >
                                            <li onClick={() => this.setState({_showMenu: null, selectedCategory: null})}>All</li>

                                            {
                                                this.state.categories.map((item, idx) => {
                                                    return (
                                                        <li onClick={() => this.setState({_showMenu: null, selectedCategory: item.name})} key={idx}>{item.name}</li>

                                                    )
                                                })
                                            }

                                        </ul>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="items">
                            {
                                this.state.templates.map((item, idx) => {
                                    if (!this.state.selectedCategory || (item.category == this.state.selectedCategory))
                                    return (
                                        <Col lg="4" xs="6" key={idx}>
                                        <article>
                                            <img src={item.pages[0]} />
                                            <div className="content">
                                                <h6>{item.name}</h6>
                                                <p>{item.category}</p>
                                            </div>
                                            <Button className="button content-overlay">
                                                <Link to={`/dashboard/${item._id}`}><button>ORDER SITE</button></Link>
                                            </Button>
                                        </article>
                                    </Col>
        
                                    )
                                })
                            }



                        </Row>
                    </Container>
                </section>

                <section className="reviews">
                    <Container>
                        <Row>
                            <Col lg="4" className="left">
                                <h3>What Customers<br /> <span>Have To Say</span></h3>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
                            </Col>
                            <Col lg="8" className="slider-container">
                                <Slider {...settings}>
                                {
                                    this.state.reviews.map((item, idx) => {
                                        return (
                                            <div className="review" key={idx}>
                                            <Isvg src={quote} />
                                            <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                                            <div className="user">
                                                <img src={item.image} />
                                                <span>{item.name}</span>
                                            </div>
                                        </div>
    
                                        )
                                    })
                                }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="bottom-section">
                    <Container>
                        <Row className="top">
                            <Col lg="6">
                                <h3>Try how easy You can order<br /> <span>you custom website</span></h3>
                            </Col>
                            <Col lg="3">
                                <button onClick={() => {
                                    if (!this.templatesSection) {
                                        return;
                                    }
                                    this.templatesSection.scrollIntoView({ behavior: 'smooth' });

                                }}>GET STARTED</button>
                            </Col>

                            <Col lg="3" className="image">
                                <img src={anim} />
                            </Col>

                        </Row>
                    </Container>

                </section>
                <footer>
                    <Container>
                        <Row>
                            <Col lg={{ size: 4 }}>
                                <Isvg src={footerLogo} />
                                <p>Lorem ipsum dolor sit amet, consetetur
sadipscing elitr, sed diam nonumy eirmod
tempor invidunt ut labore et dolore magna
aliquyam erat, sed diam voluptua.</p>

                            </Col>
                            <Col lg={{ size: 2, offset: 1 }}>
                                <h3>Quick links</h3>
                                <ul>
                                    <li><Link to='/'>About Us</Link></li>
                                    <li><Link to='/'>Terms and Services</Link></li>
                                    <li><Link to='/'>Privacy Policy</Link></li>
                                    <li><Link to='/'>FAQ</Link></li>

                                </ul>
                            </Col>
                            <Col lg="3">
                                <h3>Contact Info</h3>
                                <div className="contact-item">
                                    <Isvg src={map} />
                                    Address goes here City and Country
                                </div>
                                <div className="contact-item">
                                    <Isvg src={phone} />
                                    31654 32132
                                </div>
                                <div className="contact-item">
                                    <Isvg src={mail} />
                                    Info@develry.com
                                </div>
                            </Col>
                            <Col lg="2">
                                <h3>Contact Info</h3>
                                <p className="social">
                                    <a href="#" target="_blank"><Isvg src={facebook} /></a>
                                    <a href="#" target="_blank"><Isvg src={footerInstagram} /></a>


                                </p>
                            </Col>

                            <Col lg="12" className="spacer">
                            </Col>
                            <Col lg="12" className="copyright">
                                <p>Copyright © Devlery - 2019. All Rights Reserved.</p>
                                <p>Created by <a href="https://novamedia.agency">NOVA media</a></p>
                            </Col>
                        </Row>
                    </Container>
                </footer>


            </div >
        );
    }
}

export default Page(HomePage);